.block {
	@include flexbox-horizontal;
	flex-wrap:wrap;
	justify-content: center;
	align-content: center;
	height: 40vw;
	max-height: 50vh;

	&--facts {
		height: 700px;
		max-height: none;
	}

  &__col {
    @include flexbox-vertical;
    flex:1;

    &:hover {
      text-decoration: none;
    }
  }
  &__col--double {
    flex:2;
  }
	&__row {
		@include flexbox-horizontal;
		flex: 1;
		margin-bottom: 5px;
	}
	&__row--double {
		flex:2.45;
	}

  &--grey {
  	background: $clr-lightgrey;
  	padding: 90px;
  }
}

@media (max-width: 1024px) {
  .block {
    max-height: none;
    height: auto;

    &__col {
      min-height: 350px;
      flex-basis: 50%;
      margin-bottom: 5px;
    }
		// &__row {
		// 	min-height: 350px / 2;
		//
		// 	.block__col {
		// 		min-height: 1px;
		// 	}
		// }
  }
}

@media (max-width: 512px) {
  .block {
    max-height: none;
    height: auto;

    &__col {
      min-height: 280px;
      flex-basis: 100%;
      margin-bottom: 5px;
    }
  }
}
