.hero {
  min-height: 30vw;
  background-position: 50% 20%;
  background-size: cover;
	margin: 40px 0;
	@include flexbox-vertical;
	justify-content: center;

  .quote {
    padding: 5vw 12vw 12vw 5vw;
    font-size: 2rem;
    line-height: 2.6rem;
    max-width: 55%;
  }

}

.hero__text {
	font-size: 28px;
	line-height: 37px;
  padding: 10vw 12vw;
}

.hero--contact {
	//max-height: 50vw;
	min-height: 35vw;
	margin: 5px 0;
	background-position-y: 50%;
	display: block;

  .hero__text {
    padding:0;
  }
}

.hero--contact__block {
    padding: 30px 23px 35px;
}

.hero--contact .underscore--title:after {
    margin: 10px 0;
    width: 27px;
}

.hero__address {
    font-size: 11px;
    letter-spacing: 1px;
    margin: 20px 0 25px;
    line-height: 13px;
}

.hero-link {
  margin: 0 10px;
}

@media (min-width: 1600px) {
  .hero {
    min-height: 480px;

    &__text {
      padding: 80px;
    }
  }
}
