/*****************/
/* MAIN SETTINGS */
/*****************/

html { font-size:13px; }
body * { box-sizing: border-box; @include smooth-text(); }
html, body { height:100%; margin:0; padding:0; @include opensans(); }
body, h1, h2, h3, h4, h5, h6 { line-height: 1; font-size:1rem; }
.googlemaps { max-width: 100%; min-width: 100%;
  img { max-width: none; }
}
.clickable { cursor: pointer; }
p:first-child { margin-top:0; }
::selection { background: $clr-selection; color:#fff; }
::-moz-selection { background: $clr-selection; color:#fff; }
.placeholder .form-label { display: none; }
textarea { resize:none; }
svg, img[src$=".svg"] { max-width: 100%; }
[data-sr] { visibility: hidden; }

/*************/
/* STRUCTURE */
/*************/

.site-wrap { height:100%; min-height:100%; height:auto; margin-bottom: -$footerheight - $footermargin;
  @media (max-width: $screen-phone) { & { margin-bottom: 0; } }
}
.site-header {
  background-color: #fff;
  margin-bottom: 20px;

  > .container {
    padding-bottom: 20px;
  }
}
.site-push { height: $footerheight + $footermargin; position: relative; z-index: 1;
  @media (max-width: $screen-phone) { & { display: none; } }
}
.site-footer { height: $footerheight; margin-top:$footermargin; position: relative; z-index: 2; background-color: $clr-dark;
  > .container-fluid { height:100%; padding-top:20px;  }
  @media (max-width: $screen-phone) {
    & { height:auto;
      & > .container-fluid { height:auto; padding-bottom: 20px; }
    }
  }
}
#mnvr { position:absolute; bottom:0; right:0;
  .inner { display:block; width:13px; height:6px; margin:auto; background:url('#{$imgpath}mnvr-dark.png') no-repeat 0 0;  opacity:.3; transition:all 250ms ease-in-out;
    &:hover { width:63px; opacity:.3; }
  }
}


/***********/
/* HELPERS */
/***********/

.fill                   { width: 100%; height:100%; }
.overlay                { position: absolute; top:0; left:0; }
.up                     { @include up(); }
.text-center            { text-align: center; }
.text-left              { text-align: left; }
.text-right             { text-align: right;
  @media (min-width: $screen-md-min) {
    &--md {
      text-align: right;
    }
  }

}
.text-justify           { text-align: justify; text-align-last: center; }
.clear                  { clear:both; }
.more-padding           { padding: 65px;
  @media (max-width: $screen-md-min ) {
    padding: 45px;
  }
  @media (max-width: 600px ) {
    padding: 25px;
  }
}
.side-line {
  border-right: 1px solid $clr-darkblue;
  @media (max-width: $screen-xs-max) {
    border-right: 0;
    border-bottom: 1px solid $clr-darkblue;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
}
@media (min-width: $screen-sm-min) {
  .col-sm-offset-1-5 { margin-left: (100% / 8); }
}

.vertical-spacer        { height:60px; }

/* flexbox */
.middle                 { @include flexbox-vertical; justify-content:center;
  .no-flexbox &         { display: table;
    > *                 { display: table-cell; vertical-align: middle; }
  }
}
.middle-center          { @extend .middle; align-items:center;
  .no-flexbox & > *     { text-align: center; }
}

.halign				  { @include flexbox-horizontal; justify-content: center; }
.valign				  { @include flexbox-vertical; justify-content: center; }
.chalign				{ @extend .halign; align-items: center; }
.cvalign				{ @extend .valign; align-items: center; }
.flex-end				{ @include flexbox-vertical; justify-content: flex-end; padding-bottom: 50px; }

// .flex1 { flex: 1 1 auto; }
// .flex2 { flex: 2 1 auto; }
// .flex3 { flex: 3 1 auto; }

.sticky {
  @media (min-width: 767px) { & {
    &.sticky-on {
      position: fixed;
    }

    position: -webkit-sticky;
    position: sticky;
    top:0;

    .no-csspositionsticky & {
      position: block;
    }
  }}
}

.columns {
  column-count: 2;
  column-gap: $grid-gutter-width;
}

.row--shrink {
  margin-left: 0;
  margin-right: 0;
}


/***********************/
/* BOOTSTRAP OVERRIDES */
/***********************/

.jumbotron, .navbar { margin-bottom: 0; }
.navbar-toggle { border-color:$clr-iron;
  .icon-bar { background-color: $clr-iron; }
}


/*****************/
/* STYLE CLASSES */
/*****************/

.clr-white      { color:#fff; }
.clr-primary    { color:$clr-primary; }
.clr-secondary  { color:$clr-secondary; }

.bg-primary     { background-color:$clr-primary; }
.bg-secondary   { background-color:$clr-secondary; }
.bg-silver      { background-color:$clr-silver; }
.nopadding		{ padding: 0 !important;}

/*********************/
/* BUTTONS OVERRIDES */
/*********************/

// .btn { padding-left:40px; padding-right:40px; border-width: 2px; .font-franklin-medium();
//   transition:background-color 150ms @easeOutCubic, color 150ms @easeOutCubic, border-color 150ms @easeOutCubic;
// }
// .btn-primary {
//   .button-variant(@clr-primary, transparent, @clr-primary);
//   &:hover, &:active, &:focus { background-color:@clr-primary; color:#fff; border-color:@clr-primary; }
// }
// .btn-white {
//   .button-variant(#fff; transparent; #fff);
//   &:hover, &:active, &:focus { background-color:@clr-secondary; border-color:@clr-secondary; }
// }
