.page-section {
  padding:60px 0;

  /************/
  /* ELEMENTS */
  /************/

  &__header {
    text-align: center;
    margin-bottom: 40px;
  }

  &__title {
    @include karma();
    font-size: 18px;
  	color: $clr-darkblue;
  	margin-bottom: 30px;
  }

  &__content {
    text-align: center;
  }

  .page-section__text {
  	color: $clr-blackgrey;
  	// font-weight: 500;
  }

  /*************/
  /* MODIFIERS */
  /*************/

  &--md {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  &--lg {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  &--nopadtop {
    padding-top:0;
  }

}

@media (max-width: $screen-xs-max) {
  .page-section:first-child {
    padding-top:0;
  }
}
