.news-item {
  margin-bottom: 4vw;
  text-align: left;

  &__title {
    @extend .serif-text;
    color: $clr-primary;
  	font-size: 20px;
    margin-bottom: 5px;
    margin-top:0;
  }
  &__subtitle {
    @extend .serif-text;
    color: $clr-primary;
    margin-bottom: 15px;
  }

  .image-box {
    min-height: 240px;
  }

}

@media (max-width: $screen-xs-max) {
  .news-item {
    // text-align: left !important
    margin-bottom: 70px;

    &__title {
      margin-top: 20px;
    }
  }
}
