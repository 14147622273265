@import 'variables';
@import 'mixins';
@import 'fonts';
@import 'bower_components/bootstrap-sass/assets/stylesheets/_bootstrap';
@import 'globals';
@import 'bower_components/outdated-browser/outdatedbrowser/outdatedbrowser.scss';
@import 'bower_components/slick-carousel/slick/slick.scss';
@import 'bower_components/slick-carousel/slick/slick-theme.scss';


// partials
@import 'partials/bodytext';
@import 'partials/pagination';
@import 'partials/menu';
@import 'partials/page-sections';
@import 'partials/block';
@import 'partials/box';
@import 'partials/quote';
@import 'partials/anchor';
@import 'partials/news-item';
@import 'partials/news';
@import 'partials/seminar';
@import 'partials/hero';
@import 'partials/sidebar';
@import 'partials/share';
@import 'partials/speaker';
@import 'partials/slick';

.image-box {
  background-size: cover;
  background-position: 50% 50%;

  &--brw {
    border-left: 10px solid #fff;
    border-right: 10px solid #fff;
  }
  &--top {
    background-position:50% 0;
  }

	@media (max-width: $screen-xs-max) {
		min-height: 20vh;
		min-height: 20vh;
	}
}

@import 'others'; /* Misc stuff */
