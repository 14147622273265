.slick-arrow {
  &:before {
    font-family: sans-serif;
  }
  &.slick-prev {
    @extend .triangle;
    @extend .triangle--left;
  }
  &.slick-next {
    @extend .triangle;
    @extend .triangle--right;
  }
  &.slick-disabled {
    opacity: .3;
  }
}
