.sidebar {
  background: #fff;

  &__content {

  }

  &__section {
    padding: 25px;

    &:not(:last-child) {
      border-bottom: 1px solid $clr-silver;
    }

    &__title {
      @include karma();
      font-size:1.5rem;
      color: $brand-primary;
      margin: 0 0 25px 0;
    }
  }
}
