.news {

  &__main {
    padding-right: 4vw;
  }


  &__title {
    font-size:2.8rem;
    color: $brand-primary;
    margin: 0 0 5px 0;
  }
  &__subtitle {
    font-size: 1.6rem;
    color: $brand-secondary;
    margin: 0;
  }
  &__image {
    img {
      width: 100%;
    }
  }
  &__header {
    margin-bottom:25px;
  }

  &__author {

    &:not(:first-child) {
      margin-top: 25px;
    }

    &__name {
      @include karma();
      margin-bottom: 4px;
      color: $brand-primary;
    }

    &__info {
      margin-bottom: 15px;
      font-size: .9rem;
    }

    &__contact {
      font-size: 80%;
      @include up();
    }

  }

  &__sharing {

  }
}
