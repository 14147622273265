.quote {
  color:#fff;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  padding: 0 0 0 4vw;

  &__text:before {
    background-image: url("#{$imgpath}quotemark.svg");
    display: inline-block;
    background-size: 20px 16px;
    width: 20px;
    height: 16px;
    content: "";
    float: left;
    margin-right: 10px;
  }

  &__name {
    font-weight: 500;
    font-size:11px;
  }


  &--alpha {
    color: fade-out(#fff, .4);

    .quote__name {
      color:#fff;
    }
  }

  &--left {
    padding-right: 4vw;
    padding-left: 0;
    text-align: left;
  }

  &--top {
    margin-top: 40px;

    .quote__text:before {
      display: block;
      float: none;
      margin-bottom: 9px;
    }
  }

  &--primary {
    color: $brand-primary;

    .quote__text:before {
      background-image: url("#{$imgpath}quotemark-blue.svg");
    }
  }
}
