
/* helpers */
@mixin reset          { margin:0; padding:0; border:none; background:none; list-style: none; line-height: 1; box-shadow: none; }
@mixin smooth-text    { -webkit-font-smoothing:antialiased; text-rendering: optimizeLegibility; -moz-osx-font-smoothing: grayscale; }
@mixin up             { text-transform: uppercase; }
@mixin clearfix       { zoom:1;
  &:before,
  &:after             { content:""; display:table; }
  &:after             { clear: both; }
}

@mixin tinyfont {
  font-size: .8rem;
}

/* borders mixins */
@mixin borders($t, $r, $b, $l) {
  border-top-width: $t;
  border-right-width: $r;
  border-bottom-width: $b;
  border-left-width: $l;
}

/* flexbox shortcuts */
@mixin force-flex           { display: flex !important; }
@mixin flexbox-vertical     { display:flex; flex-direction:column; }
@mixin flexbox-horizontal   { display:flex; flex-direction:row; }
@mixin flex($x: 1)          { flex:$x; }
.flexrow                    { @include flexbox-horizontal; flex-wrap:wrap; }
.jstify-center				      { justify-content: center;}
:not(html).flexbox          { display: flex;}

@media (min-width: 767px) {
	.flexrow-sm { display: flex; }
}
@media (min-width: 992px) {
	.flexrow-md { display: flex; }
}


/* spacer classes */
/* TODO: convert LESS to SASS */
// .spacer(@m, @dir) when (@m <= 200) {
//   .spacer--@{dir}@{m} { margin-@{dir}:~"@{m}px"; }
//   .spacer(@m+10, @dir);
// }
// .spacer(10, top);
// .spacer(10, bottom);


/* svg background fallback */
/* TODO: convert LESS to SASS */
// .svgbg(@src, @w, @h, @fallback: '') { background:url(@src) no-repeat 0 0; width:@w; height:@h; display: inline-block; background-size:@w @h;
//   .no-svg & { background:url(@fallback); }
// }
// .svgbg(@src, @w, @h, @fallback: '') when(@fallback = '') {
//   @fallback: ~`"@{src}".replace('.svg', '.png')`;
//   .no-svg & { background:url(@fallback); }
// }
