.speaker-item {

  &__info {
    background-color: $clr-secondary;
    color:#fff;
    padding: 35px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin: 0 0 15px;
  }

  &__title {
    @include karma();
    font-size: 1.5rem;
    margin: 0;
  }

  &__sector {
    font-size: .8rem;
    margin: 0 5px 5px;
  }

  &__subject {
    @include karma();
    font-size: 1.1rem;
    margin: 0 0 15px;
  }

  &__date {
    @include karma();
    color: rgba( white, .45 );
    font-size: .9rem;
    margin: 0 0 5px;
  }

  .read-more {
    color: rgba( white, .45 );
  }

  .quote {
    max-width: 55%;
    margin-left: 4%;
    margin-bottom: 50px;
  }
}

.speakers-carousel {
  color: #fff;
  @include karma();
  height: 250px;

  &__item {
    @include force-flex;
    width: 20%;
    float: left;
    height: 100%;

    &__title {
      font-size: 1.5rem;
      margin: 0;

      &:after {
        content: "";
        display: block;
        width: 25px;
        height: 2px;
        background: #fff;
        margin: 10px auto;
      }
    }

    &__sector {
      font-size: 1rem;
    }
  }
}

/*
  .speaker-item__title {
    margin: 80px 0 0;

  }



}*/

.hero-link {
  color: $clr-darkgrey;
}

.speaker-article {
  text-align: justify;

  &__bigtitle {
    @include karma();
    color: $clr-primary;
    font-size: 1.2rem;
  }

  &__date {
    @include karma();
    color: $clr-lightblue;
    font-size: .8rem;
    margin: 5px 0 15px;
  }

  &__subject {
    color: $clr-lightblue;
    margin: 15px 0 5px;;
  }

  &__text {
    font-size: .9rem;
    line-height: 1.1rem;
  }

  &__zoomtext {
    display: block;
    border: 1px solid $clr-primary;
    border-left: none;
    border-right: none;
    padding: 15px 45px;
    margin: 20px 60px;

    &__title {
      color: $clr-primary;
      line-height: 1.7rem;
    }

    &__text {
      line-height: 1.5rem;
    }
  }

  &__share {
    color: $clr-primary;
    @include karma();
    font-size: 1.1rem;
    margin: 15px 0;
    vertical-align: middle;
  }
  &__info {
    display: block;
    background-color: $clr-lightgrey;
    color: $clr-primary;
    padding: 40px;

    &__name{
      @include karma();
      font-size: 1.2rem;
      margin: 0 0 15px;
    }

    &__text {
      font-size: .9rem;
      line-height: 1.3rem;
    }
  }
  .follow__icon {
    font-size: 1rem;
    margin: 0 5px 0 0;
  }

  .underscore--title:after {
    width: 25px;
    margin: 15px 0;
  }
}
