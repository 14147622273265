.seminar-item {
  margin-bottom: 60px;

  &__title {
    margin:15px 0;
  }


  .read-more {
    margin-top:15px;
  }
}

// @media (min-width: $screen-sm-min) {
//   .seminar-item {
//     margin-bottom: 7vw;
//   }
// }
