@mixin opensans($weight: 400) {
  font-family: 'Open Sans', 'opensans', Helvetica, sans-serif;
  font-weight: $weight;
}
@mixin karma() {
  font-family: "Karma", Georgia, serif;
}

@font-face {
  font-family: "social-icons";
  src:url("#{$fontpath}social-icons.eot");
  src:url("#{$fontpath}social-icons.eot?#iefix") format("embedded-opentype"),
    url("#{$fontpath}social-icons.woff") format("woff"),
    url("#{$fontpath}social-icons.ttf") format("truetype"),
    url("#{$fontpath}social-icons.svg#social-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[class^="social-icon-"]:before,
[class*=" social-icon-"]:before {
  font-family: "social-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.social-icon-facebook-circle:before {
  content: "a";
}
.social-icon-facebook:before {
  content: "b";
}
.social-icon-linkedin-square:before {
  content: "d";
}
.social-icon-google-plus-square:before {
  content: "e";
}
.social-icon-social-twitter:before {
  content: "f";
}
.social-icon-vimeo-square:before {
  content: "g";
}
.social-icon-facebook-square:before {
  content: "h";
}
.social-icon-pinterest:before {
  content: "i";
}
.social-icon-linkedin-outline:before {
  content: "j";
}
.social-icon-twitter-outline:before {
  content: "k";
}
.social-icon-facebook-outline:before {
  content: "l";
}
.social-icon-google-plus-outline:before {
  content: "m";
}
.social-icon-pinterest-outline:before {
  content: "n";
}
.social-icon-snapchat:before {
  content: "o";
}
.social-icon-youtube-square:before {
  content: "p";
}
.social-icon-twitter:before {
  content: "q";
}
.social-icon-twitter-square:before {
  content: "r";
}
.social-icon-twitter-circle:before {
  content: "t";
}
.social-icon-linkedin-circle:before {
  content: "s";
}
.social-icon-pinterest-square:before {
  content: "c";
}
.social-icon-pinterest-circle:before {
  content: "u";
}
.social-icon-instagram-circle:before {
  content: "v";
}
.social-icon-instagram:before {
  content: "w";
}
