.anchor {
  &__title {
    @extend .page-section__title;
    @extend .serif-text;
    margin-top:25px;
  }
  &__circle {
    display: block;
    width: 30px;
    height: 30px;
    background: $clr-darkblue;
    border-radius: 50%;
    margin: 0 auto;
    color: white;
    line-height: 30px;
    text-align: center;

    &--small {
      @extend .anchor__circle;
      display: inline-block;
      width: 26px;
      height: 26px;
      font-size: 9px;
      line-height: 26px;
      margin: 0 8px 20px 0;
      position: relative;
      top: 5px;
    }
    &--xsmall {
      @extend .anchor__circle--small;
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 8px;
    }
    &--inline {
      position: static;
      margin: 0 8px 3px 0;
    }
    &--center {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__text {
  	font-size: 10px;
  	color: $clr-lightblue;
  	letter-spacing: 1px;
  	font-weight: 400;
  	display: block;
  	padding: 0 10px;
  }
}

@media (min-width: $screen-md-min) {
  .anchor__text {
    padding-left: 60px;
    padding-right: 60px;
  }
}
