/*** COLORS ***/
$clr-darkblue: #00589a;
$clr-lightblue: #257abf;
$clr-blackgrey: #282829;
$clr-darkgrey: #9c9c9c;
$clr-lightgrey: #eeeeef;

$clr-primary:     $clr-darkblue; //#616F65;
$clr-secondary:   $clr-lightblue;
$clr-dark:        #222;
$clr-silver:      #F5F5F5;
$clr-iron:        #999;

$brand-primary:   $clr-primary;
$brand-secondary: $clr-secondary;

$clr-selection:   fade-out($clr-primary, .2);

$clr-facebook:    #3b5998;
$clr-twitter:     #00a0d1;
$clr-pinterest:   #910101;
$clr-vimeo:       #44bbff;
$clr-tumblr:      #34526f;
$clr-youtube:     #c4302b;
$clr-foursquare:  #3AB6E8;
$clr-instagram:   #517fa4;
$clr-linkedin:    #1884bc;
$clr-googleplus:  #f44336;

/*** PATHS ***/

$imgpath:         '/themes/tvh16/assets/img/'; //'/sites/all/themes/front/assets/img/';
$fontpath:		    '/themes/tvh16/assets/fonts/';

/*** LAYOUT ***/

$footermargin:    40px;
$footerheight:    46px;

$radius: 10px;


/*** RESPONSIVE ***/
$screen-phone:    '767px';


/*** EASING ***/

$linear         : cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease           : cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in        : cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out       : cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out    : cubic-bezier(0.420, 0.000, 0.580, 1.000);
$easeInQuad     : cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic    : cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart    : cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint    : cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine     : cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo     : cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc     : cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack     : cubic-bezier(0.600, -0.280, 0.735, 0.045);
$easeOutQuad    : cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic   : cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart   : cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint   : cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine    : cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo    : cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc    : cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack    : cubic-bezier(0.175, 0.885, 0.320, 1.275);
$easeInOutQuad  : cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart : cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint : cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine  : cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo  : cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack  : cubic-bezier(0.680, -0.550, 0.265, 1.550);
