
.navbar-brand {
  padding-top: 0;
  height: auto;
}
.navbar-header {
  position: relative;
  z-index: 9999;
}

.section-2 .page-section__quote {
    padding: 0;
    font-size: 20px;
    line-height: 28px;
}

.page-section__anchors {margin: 40px 0 40px;}

.section {
    // max-height: 65vh;
    //height: 40vw;

    @media (max-width: $screen-xs-max) {
      max-height: none;
    }
}

.read-more {
	display: block;
	font-size: 10px;
	text-transform: uppercase;
	margin: 30px 0;
}
.view-all {
  font-size: 10px;
  @extend .grey-text;
  @include up();
}
.tiny-arrow {
  // font-size: 100%;
}
.section-1__block {
    border-right: 1px solid $clr-darkblue;
}

.section__title {
	font-size: 12px;
	font-weight: 500;
	line-height: 25px;
}

.textblock {
    margin: 0 0 30px;
}

.textblock .page-section__title {
    margin: 0;
}
//background styles
.blue-bg {background-color: $clr-darkblue;}
.grey-bg {background-color: $clr-darkgrey;}
.white-bg {background: white;}
.lightblue-bg {background: $clr-lightblue;}
.lightgrey-bg {background: $clr-lightgrey;}
.lightblue-bg,
.lightgrey-bg {
	//height: 100%;
	display: flex;
    justify-content: center;
    flex-flow: column wrap;
}

.signs--text {
	font-size: 10px;
	margin: 30px 0 10px !important;
}

.text--date,
.text--country {font-size: 11px;}

//text styles
.grey-text {color: $clr-darkgrey !important;}
.blue-text {color: $clr-darkblue;}
.lightblue-text {color: $clr-lightblue;}
.black-text {color: $clr-blackgrey;}
.white-text {color: white;}
.sanss-text { @include opensans(); }
.serif-text { @include karma(); }
.small-text {
	@include tinyfont;
	line-height: 1.9;
}

//drawings
.blue-line {
    display: block;
    width: 25px;
    height: 1px;
    background: $clr-lightblue;
    margin: 30px 0;
}

.underscore--title:after {
    content: "";
    display: block;
    width: 17px;
    height: 1px;
    background: $clr-darkblue;
    margin: 10px auto;
}

.longlines--title {
  position: relative;
	margin-bottom: 85px;

  span {
    position: relative;
    z-index: 2;
  }

  &::before {
    display: block;
    position: absolute;
    top:50%;
    content: "";
    width:100%;
    height: 1px;
    border-bottom: 1px solid #ddd;
    z-index: 1;
  }
}

.longlines--title span {
    background: #fff;
    padding: 0 15px;
}

.park,
.account {
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
	@include flexbox-horizontal;
	align-items: center;
	margin: 0 0 10px;
}

.park:before,
.account:before {
	content: "";
	background-image: url("#{$imgpath}parking.svg");
    display: inline-block;
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    margin: 0 5px 0 0;
}

.account:before {
	background-image: url("#{$imgpath}account.svg");
	background-size: 10px 8px;
	width: 10px;
	height: 8px;
	margin: 0 10px 0 2px;
}

.triangle {
  width: 0;
  height: 0;

  &--left {
    border-top:5px solid transparent;
    border-right: 10px solid $clr-primary;
    border-bottom:5px solid transparent;
  }

  &--right {
    border-top: 5px solid transparent;
    border-left: 10px solid $clr-primary;
    border-bottom: 5px solid transparent;
  }
}
//backtotop
.gotop {
	text-transform: uppercase;
	font-size: 10px;
}
///////
.site-footer {
	background: $clr-lightgrey;
}
.follow {
	display: flex;
	align-items: center;
	padding-top: 15px;
}

.follow__text {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 10px;
	font-weight: 400;
	margin: 0 10px;
}

.follow__icon {
	color: $clr-darkblue;
	font-size: 1.2rem;
	margin: 0 10px 0 0;
}
