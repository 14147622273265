.share {
  font-size:1.3rem;

  a {
    padding: 0 5px;


    &:hover, &:active, &:focus {
      text-decoration: none;
    }
  }
}
