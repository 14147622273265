
.box {
	background: $clr-lightblue;
	padding: 20px;
	margin: 0 5px 5px 0;
	background-size: cover;
	background-position: 50% 50%;
	flex:1;

	@media (max-width: 1024px) {
		min-height: 250px;
	}

	&__title {
		font-family: "Georgia", serif;
		font-size: 1.3rem;
		color: white;
		margin-top: 5px;
	}

	&__subtitle {
		@include tinyfont;
		color: white;
		text-transform: uppercase;
		white-space: nowrap;
	}

	&__arrow {
		display: inline-block;
		transition: transform 150ms $easeInOutCubic;
	}

	&--wide {
		background: $clr-darkblue;
		text-align: center;
		@include flexbox-vertical;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		justify-content: center;
		height: inherit;
    max-width: 100%;

		.block__row--double .box--end & {
			// HACK: shift the content up, so it aligns with the other boxes.
			margin-bottom: 37px;
		}

		&__icon {
			height: 30px;
			margin-bottom: 10px;
		}

		&__number {
			font-size: 3rem;
			color: white;
			font-weight: 700;
			margin-bottom: 10px;

			&:before {
		    content: "";
		    display: block;
		    width: 90px;
		    height: 1px;
		    background-color: rgba(#fff, .5);
		    margin: 0 auto;
		    text-align: center;
		    margin-bottom: 10px;
			}
		}

		&__text {
			color: #fff;
			max-width: 77%;
			line-height: 1.3rem;
			font-weight: 600;
		}
	}

	// &--smlr { margin-right: 0; }
	&--fllheight { height: 100%; }
	&--grey { background: $clr-lightgrey; }
	&--black { background: $clr-blackgrey; }
	&--blue { background: $clr-darkblue; }
	&--lightblue { background: $clr-lightblue; }
	&--double { flex:2.25; }
	&--flex { @include flexbox-vertical; justify-content: center; align-items: center; }
	&--end {
		justify-content: flex-end;
	}
	&--right {
		align-items: flex-end;
	}

	&:last-of-type,
	&:nth-of-type(3),
	&--fllheight { margin-bottom: 0; }

	&--grey &__title,
	&--wide &__title { color: $clr-lightblue; }

	&--grey &__subtitle {color: $clr-lightblue;}

	&__title--lighter { color: lighten($clr-lightblue, 35%) !important; }

	&--wide &__subtitle {
	  margin: 50px 0 0;
	}

	&__col:hover,
	&:hover {
		text-decoration: none;
		outline: none;
		color: none;

		.box__arrow {
			transform: translateX(5px);
		}
	}
}
