.flexmenu {
	@include flexbox-vertical;
	align-items: flex-end;
}

.main-menu {
	background-color: $brand-primary;
	position: fixed;
	top:0;
	left:0;
	width: calc(100vw - 50px);
	height: 100vh !important;
	border-right: 4px solid $brand-primary;
	transition: transform 400ms $easeInOutCubic;
	transform: translate3d(-100%,0,0);
	z-index: 9999;

	&.collapse.in {
		transform: translate3d(0,0,0);
	}
}

.mobile-nav-logo {
	width: 160px;
  display: block;
  margin: 20px auto;
	position: relative;
	left:10px; /* give a little nudge to center, due to some questionable parent margins */
}

.lang-menu{
	font-size:.7rem;
	text-transform: uppercase;
	margin: 10px;
	text-align: center;
	width: calc(100% - 40px);

	li {
		// float:left;
		display: inline-block;
	}

	a {
	  padding: 0 !important;
		font-size: 150%;
		margin: 0 15px;
		color: $clr-darkgrey;

		@media (min-width: $screen-sm-min) {
			margin: 0 5px;
			font-size: 100%;
		}
	}
}


.site-menu {
	font-size: 16px;
	width: 100%;

	// background-color: $brand-primary;

	li {
	  border-right: 1px solid $clr-darkblue;
	  padding-top: 0;
	  padding-bottom: 0;
		margin: 15px 0;

		&:last-of-type {
	    border-right: none;
		}
	}

	li > a {
	  @extend .serif-text;
	  // @extend .blue-text;
		color: #fff;

	  padding-bottom: 0 !important;
	  padding-top: 0 !important;
		padding-right: 10px;
		padding-left: 10px;

	  transition: background 150ms ease-in-out, color 150ms ease-in-out;

	  &:hover, &:active, &:focus {
	    background: $clr-primary !important;
	    color:#fff;
	  }
	  &.active {
	    text-decoration: underline;
	  }
	}
}

.navbar-toggle {
	border: none;

	.icon-bar {
		background-color: $brand-primary;
	}
}

@media (min-width: $screen-sm-min) {
	.main-menu {
		background-color: transparent;
		width: auto;
		border:none;
		position: relative;
		transform: translate3d(0,0,0);
	}
	.site-menu {
		font-size: .9rem;
		width: auto;

		li {
			margin-top:0;
			margin-bottom: 0;

			> a {
				color: $brand-primary;
			}
		}
	}
	.mobile-nav-logo {
		display: none;
	}
	.lang-menu {
		width: auto;
	}
}

@media (min-width: $screen-md-min) {
	.site-menu {
		li {
			> a {
				padding-left:15px;
				padding-right: 15px;
			}
		}
	}
}
